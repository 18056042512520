<template>
  <en-drawer :model-value="modelValue" title="一键领料" @close="$emit('update:model-value', false)">
    <en-form :model="form.data" :rules="form.rules" :ref="setRef('form')">
      <en-form-item label="车牌" prop="supplier.id">
        <select-maintain
          v-model="form.data.vehicle"
          :ajax="{
            action: 'GET /enocloud/common/customer',
            params: (value) => ({ name: value, type: 'B', status: 'A', rsexp: 'id,name' })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
      <en-form-item label="工单" prop="warehouse.id">
        <select-maintain
          v-model="form.data.workorder"
          :ajax="{
            action: 'GET /enocloud/common/warehouse',
            params: (value) => ({ name: value })
          }"
          :props="{ label: 'name', value: '' }"
          value-key="id"
          remote
          class="w-full"
        ></select-maintain>
      </en-form-item>
    </en-form>

    <template #footer>
      <en-button @click="footer.cancel.click">取消</en-button>
      <button-ajax :method="footer.confirm.click">确定</button-ajax>
    </template>
  </en-drawer>
</template>

<script lang="ts">
import { calculator } from '@enocloud/utils'

export default factory({
  props: {
    modelValue: Boolean,
    data: Object as PropType<EnocloudInventoryDefinitions['PurchaseDto']>
  },

  emits: {
    'update:model-value': (value: boolean) => typeof value === 'boolean',
    success: (value: number | undefined) => ['number', 'undefinded'].includes(typeof value)
  },

  watch: {
    modelValue: {
      async handler() {
        if (this.modelValue) {
        }
      }
    }
  },

  config: {
    children: {
      form: {
        data: {
          vehicle: '',
          workorder: null
        },
        rules: {}
      },
      footer: {
        cancel: {
          click() {
            this.emit('update:model-value', false)
          }
        },
        confirm: {
          async click() {}
        }
      }
    }
  }
})
</script>
